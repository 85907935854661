import * as constants from './constants.js';
import * as functions from './functions.js';
import * as layers from './layers.js';
import map from './map.js';
import * as popups from './popups.js';
import * as sources from './sources.js';

// Our two main entry points for JavaScript on this website are in map.on('load')
// and ready(), below.

// before the page loads, parse the URL search parameters, if any
const pageParams = new URLSearchParams(window.location.search);

// Check validity of parameters that we are interested in enforcing
// particular values for and build our own property list based on this
let cleanedParams = {};

// note: we're just taking the first value right now; later we may need to support
// multiple ones
for (let [key, validator] of Object.entries(constants.paramValidators)) {
    if (pageParams.has(key) && validator(pageParams.get(key))) {
        cleanedParams[key] = pageParams.get(key);
    }
}

// if the page parameter indicates that we are being embedded by GridFast,
// do a few things to fit their requirements:
// - automatically dismiss the disclaimer on load
// - hide the left UI pane
// - filter out all of the hexes in the hex layer

// We can do the first two here before the map loads. Filtering the hexes has to
// happen in functions.applyParamValues()
if (
    cleanedParams.hasOwnProperty('gridFast') &
    (cleanedParams['gridFast'] == 'true')
) {
    document.getElementById('console').classList.add('hidden');
    functions.dismissInstructions();
}

// if we have a map center parameter and/or a zoom, place the map there
let mapParams = {};
if (cleanedParams.hasOwnProperty('center')) {
    mapParams.center = cleanedParams.center
        .split(',')
        .map((x) => parseFloat(x, 10));
}
if (cleanedParams.hasOwnProperty('zoom')) {
    mapParams.zoom = parseInt(cleanedParams.zoom, 10);
}

// When the page loads...
functions.ready(function () {
    // color in the legends
    functions.setHostingLegend();
    functions.setLayerGradientColorLegends();

    // Needs to happen after setting up the hex value filter, or else the filter values
    // are constrained by what is in the map viewport on load, not the whole
    // dataset
    map.jumpTo(mapParams);

    // reset the layer switcher
    document.getElementById('layer-select').selectedIndex = null;

    // populate the contact email address
    document.getElementById(
        'contact_email'
    ).href = `mailto:${constants.emailPrefix}@${constants.emailDomain}`;

    functions.setupEventHandlers();
});

map.on('load', function () {
    map.resize();

    let tilesources = Object.keys(sources);

    for (const s of tilesources) {
        // mapbox cannot just make things easy can it
        if (sources[s].url.startsWith('mapbox://')) {
            map.addSource(sources[s].id, {
                type: 'vector',
                url: sources[s].url,
            });
        } else {
            map.addSource(sources[s].id, {
                type: 'vector',
                tiles: [sources[s].url],
            });
        }
    }

    // Add the base map layers, according to our map type
    const mapType = functions.getBaseMapType();

    // add layers for the static and dynamic hexes
    for (let i in layers.baseLayers) {
        map.addLayer(
            layers.baseLayers[i],
            constants.hexLayerBeforeId // existing layer to position the new one behind
        );
        // When a click event occurs on a feature in a hex layer, open a popup at the
        // location of the click, with description HTML from its properties.
        popups.addPopupHandler(layers.baseLayers[i], popups.fillHexPopup);
    }

    // Now add the other (extra) layers that people can turn on and off

    // first, hosting capacity area outlines
    map.addLayer(layers.hostingCapacityAreaLayer, constants.dataLayerBeforeId);

    // now hosting capacity detail views
    for (let i in layers.hostingCapacityDetailLayers) {
        map.addLayer(
            layers.hostingCapacityDetailLayers[i],
            constants.dataLayerBeforeId
        );
        popups.addPopupHandler(
            layers.hostingCapacityDetailLayers[i],
            popups.fillHostingCapacityPopup // we don't want popups in the area outlines
        );
    }

    // EV charging
    map.addLayer(layers.evChargingLayer, constants.dataLayerBeforeId);
    popups.addPopupHandler(layers.evChargingLayer, popups.fillEVPopup);

    // justice40 layers
    for (let i in layers.justice40Layers) {
        map.addLayer(layers.justice40Layers[i], constants.dataLayerBeforeId);
        popups.addPopupHandler(
            layers.justice40Layers[i],
            popups.fillJustice40Popup
        );
    }

    // air pollution
    for (let i in layers.pm25Layers) {
        map.addLayer(layers.pm25Layers[i], constants.dataLayerBeforeId);
        popups.addPopupHandler(layers.pm25Layers[i], popups.fillPm25Popup);
    }

    // transportation options
    for (let i in layers.transportationLayers) {
        map.addLayer(
            layers.transportationLayers[i],
            constants.dataLayerBeforeId
        );
        popups.addPopupHandler(
            layers.transportationLayers[i],
            popups.fillTransportationPopup
        );
    }

    // truck stops
    map.addLayer(layers.truckStopsLayer, constants.dataLayerBeforeId);
    popups.addPopupHandler(layers.truckStopsLayer, popups.fillTruckStopsPopup);

    // utility service areas
    map.addLayer(layers.utilityServiceAreasLayer, constants.dataLayerBeforeId);
    popups.addPopupHandler(
        layers.utilityServiceAreasLayer,
        popups.fillUtilityServiceAreasPopup
    );

    functions.applyParamValues(cleanedParams);

    // update hex resolution-dependent elements as appropriate
    map.on('zoomend', () => {
        functions.updateHexResolution();
    });
    // and do it a first time in case we're somehow not at the default one on load
    functions.updateHexResolution();
}); // end of map.on('load'...
